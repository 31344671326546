
import { environment } from '../../environments/environment';
import { Injectable } from "@angular/core";
const currentDomain = environment.API;
const currentDomainReport = environment.REPORTAPI;

// const testDomain = environment.TESTAPI;
const staticJson = 'assets/jsons/';
const firebase = 'https://fir-python1.firebaseio.com/';


@Injectable()
export class AppSettings {
    public static SESSION_KEY = {
        TOKEN: 'token'
    };
    public static URL: any = {
        LOGIN_END_POINT: currentDomain + 'web_login_user',
        logout_service: 'https://idm.zsservices.com/MomentZS/IdentityManager/app/Web/logout.aspx',
        // LOGIN_END_POINT: testDomain + 'web_login_user',
        // Email Output
        EMAIL_TABLE: currentDomain + 'getTableData',
        // EMAIL_TABLE : currentDomain + 'getTableData_dev',
        INDIVIDUAL_NOTIFICATION: currentDomain + 'individual_notification',
        BONUS_POINT: currentDomain + 'web_bonus_points',
        CURRENT_URL: currentDomain,
        CURRENT_URL_REPORT: currentDomainReport,
        PUBLISH_MESSAGE: currentDomain + 'topic_notification',
        SCHEDULE_TASK: currentDomain + 'schedule_task',
        CONFERENCE_TABS: currentDomain + 'web_get_discussion_details',
        web_chatLoad: currentDomain + 'web_chatLoad',
        web_delete_comment: currentDomain + 'web_delete_comment',
        update_message_approval_status: currentDomain + 'update_message_approval_status',
        web_chatUpdate: currentDomain + 'web_chatUpdate',
        reportUser: currentDomain + 'fetch_users_list',
        save_config: currentDomain + 'save_config',
        delete_Report: currentDomain + 'delete_config',
        duplicate_config: currentDomain + 'duplicate_config',
        fetch_existing_config_data: currentDomain + 'fetch_existing_config_data',
        delete_automatic_chort_table: currentDomain + 'delete_automatic_chort_table',

        // firebase integration
        // delete_automatic_chort_table: currentDomain + 'delete_automatic_chort_table',


        // reporting page

        reportTable: currentDomain + 'fetch_config_list',
        // reportUser: staticJson + 'reportUsers.json',
        // reportLevels: currentDomain + 'reportLevels.json',
        reportLevels: currentDomain + 'fetch_project_level_list',
        reportQuestion: currentDomain + 'fetch_questions_list',
        // reportQuestionType: staticJson + 'reportQuestionType.json',
        reportQuestionType: currentDomain + 'fetch_question_type_list',
        reportOptions: currentDomain + 'fetch_options_list',
        graphData: currentDomain + 'fetch_graphs_list',
        // graphData: staticJson + 'graphs-display.json',
        preview: currentDomain + 'preview',
        preview_charts: staticJson + 'preview_charts.json',


        // report page ends
        formJson: staticJson + 'DFMModule.json',
        sideMenu: staticJson + 'sideMenu.json',
        all_users_list: currentDomain + 'all_users_project',
        // all_users_list : staticJson + 'allusers.json',
        add_newProject: currentDomain + 'create_new_project',
        add_user: currentDomain + 'add_teamMember',
        add_template: currentDomain + 'add_new_template',
        add_activity: currentDomain + 'add_new_task_template',
        addLevel: currentDomain + 'get_template_info',
        // addLevel: testDomain + 'get_template_info',

        // addLevel : staticJson + 'addLevel.json',
        create_new_level: currentDomain + 'create_new_level',
        edit_project: currentDomain + 'edit_project',
        voicemail_config: currentDomain + 'configure_voicemail',
        tex_config: currentDomain + 'configure_onc_speak',
        breakRoom_config: currentDomain + 'configure_breakroom',
        discussion_config: currentDomain + 'configure_forum_discussion',
        email_config: currentDomain + 'configure_email',
        clinic_config: currentDomain + 'configure_patient_data',
        rep_config: currentDomain + 'configure_rep_visit',
        imageUpload: currentDomain + 'edit_profile_v3',
        audioUpload: currentDomain + 'uploadVersion3',
        return_configured_data: currentDomain + 'return_configured_data',
        // return_configured_data: staticJson + 'return_configured_data.json',
        // dropdown_details : staticJson + 'user.json',
        // chat_details : staticJson + 'chat.json',
        transcript_data: currentDomainReport + 'transcript_data',
        // transcript_data : staticJson + 'user.json',
        UPLOAD_FILE_REQUEST: currentDomain + 'csv_upload',
        getTableData_dev: currentDomainReport + 'getTableData_dev',
        publish_task_details: currentDomain + 'publish_task_details',
        publish_level_details: currentDomain + 'publish_level_details',
        publish_project_details: currentDomain + 'publish_project_details',
        set_active_level: currentDomain + 'add_groups_level',
        validate_task: currentDomain + 'validate',
        archive_level_data: currentDomainReport + 'archive_level_data',
        archive_project_data: currentDomainReport + 'archive_project_data',
        download_all_moderator_chat_data: currentDomainReport + 'download_all_moderator_chat_data',
        disJson: staticJson + 'discussionJson.json',
        save_moderator: staticJson + 'save_moderator.json',
        save_automatic_moderator: staticJson + 'save_automatic_moderator.json',
        dropdown_list: staticJson + 'dropdown_list.json',
        introFetch: currentDomain + 'fetch_intro_url', // staticJson + 'introFetch.json',
        introimageUploadService: currentDomain + 'upload_intro_screen',
        saveAllUploadsService: currentDomain + 'intro_image_url',
        intro_image_url: currentDomain + 'intro_image_url',
        delete_intro_screen: currentDomain + 'delete_intro_screen',
        fetch_intro_url: currentDomain + 'fetch_intro_url',
        save_automatic_cohort_data: currentDomain + 'save_automatic_cohort_data',
        fetch_automatic_cohort_data: currentDomain + 'fetch_automatic_cohort_data',
        fetch_the_task_list_from_previous_level: currentDomain + 'fetch_the_task_list_from_previous_level',
        delete_automatic_cohort_data: currentDomain + 'delete_automatic_cohort_data',
        handle_single_input_dropdown: currentDomain + 'handle_single_input_dropdown',
        handle_image_input_dropdown: currentDomain + 'handle_image_input_dropdown',
        get_level_details: currentDomain + 'get_level_details',
        // get_level_details : staticJson + 'get_level_details.json',
        dropdown_details: currentDomain + 'dropdown_details',
        delete_tasks: currentDomain + 'delete_tasks',
        topic_notification: currentDomain + 'topic_notification',
        fetch_task_customization: currentDomain + 'fetch_task_customization',
        fetch_moderators_list: currentDomain + 'fetch_moderators_list',
        add_a_moderator: currentDomain + 'add_a_moderator',
        save_moderator_wrt_project: currentDomain + 'save_moderator_wrt_project',
        manage_resume_functionality: currentDomain + 'manage_resume_functionality',
        fetch_moderators_wrt_project: currentDomain + 'fetch_moderators_wrt_project',
        fetch_conversations: currentDomain + 'fetch_conversations',
        insert_new_message: currentDomain + 'insert_new_message',
        transcript_data_generate_CSV: currentDomainReport + 'transcript_data_generate_CSV',
        task_customization: currentDomain + 'task_customization',
        generate_respondent_csv: currentDomainReport + 'generate_respondent_csv',
        download_respondent_csv: currentDomainReport + 'download_respondent_csv',
        download_filtered_data: currentDomain + 'download_filtered_media',
        getClinicDefaultText: staticJson + 'clinicGenericTask.json',
        getRepDefaultText: staticJson + 'repTaskGeneric.json',
        getGenericDefaultText: staticJson + 'genericTask.json',
        get_default_translations: currentDomain + 'get_default_translations',


        configure_mr_task: currentDomain + 'configure_mr_task',
        delete_s3_file: currentDomain + 'delete_s3_file',
        fetch_individual_conversation: currentDomain + 'web_fetch_individual_conversation',
        download_moderator_chat_data: currentDomain + 'download_moderator_chat_data',
        get_download_url: currentDomain + 'get_download_url',
        generate_token: currentDomain + 'generate_token',
        get_overall_project_data: currentDomainReport + 'get_overall_project_data',
        individual_progress_details: currentDomainReport + 'individual_progress_details',
        get_overall_level_data: currentDomainReport + 'get_overall_level_data',

        // Get Task details for level wise cohorting
        // getTaskDetailForLevelCohorting: staticJson + 'temp-level-cohort.json',
        getTaskDetailForLevelCohorting: currentDomain + 'fetch_the_cohort_level_details',
        getLevelDetailsForCohort: currentDomain + 'get_level_cohorts',
        getSingleInputForCohort: currentDomain + 'handle_single_input_for_level_cohort',

        // getMrTaskListforDiscussion
        // getMrTaskDetailsForDiscussion : staticJson + 'discussion-task-logic.json'
        getMrTaskDetailsForDiscussion: currentDomain + 'fetchInLevelMrTasks',
        levels_dropdown_data: currentDomain + 'levels_dropdown_data',
        fetch_mr_dropdown_details: currentDomain + 'fetch_mr_dropdown_details',
        fetch_project_details: currentDomain + 'fetch_project_details',
        fetch_language_code_dropdown: currentDomain + 'fetch_language_code_dropdown',
        fetch_parent_text_dropdown: currentDomainReport + 'fetch_parent_text_dropdown',
        save_translation: currentDomain + 'save_translation',
        save_new_key: currentDomain + 'save_new_key',
        fetch_translation: currentDomainReport + 'fetch_translation',
        fetch_country_language_dropdown: currentDomain + 'fetch_country_language_dropdown',
        fetch_country_table: currentDomain + 'fetch_country_table',
        add_new_country: currentDomain + 'add_new_country',
        duplicate_task: currentDomain + 'duplicate_task',
        replicate_level: currentDomain + 'replicate_level',
        copy_translation: currentDomain + 'copy_translations',
        edit_translation: currentDomain + 'edit_translation',
        fetch_translation_texts: currentDomainReport + 'fetch_translation_texts',
        save_default_translations: currentDomain + 'save_default_translations',
        fetch_translation_dropdowns: currentDomain + 'fetch_translation_dropdowns',
        get_max_and_min_of_rating: currentDomain + 'get_max_and_min_of_rating',
        delete_country: currentDomain + 'delete_country',
        upload_translation_texts: currentDomain + 'upload_translation',
        upload_web_default_translation: currentDomain + 'upload_web_default_translation',
        get_webdefault_translation_keys: currentDomain + 'get_webdefault_translation_keys',
        logout_user: currentDomain + 'logout',
        get_sap_user: currentDomain + 'fetch_sap_user_details',
        migrate_project_code: currentDomain + 'migrate_project_code',
        migrate_users: currentDomain + 'migrate_users',
        data_refresh: currentDomain + 'data_refresh',

        delete_question_level_cohort_data: currentDomain + 'delete_question_level_cohort_data',
        delete_question_level_cohort_table: currentDomain + 'delete_question_level_cohort_table',
        fetch_report_status: currentDomainReport + 'fetch_report_status',
        donwload_s3_url: currentDomainReport + 'download_s3url',

        // login duplicate
        web_login: staticJson + 'webLogin.json',
        getTemplate: staticJson + 'getTemplate.json',
        fetch_moderators_wrt_project_duplicate: staticJson + 'fetchModerator.json',
        fetch_previous_tasks: staticJson + 'fetchPreviousTaks.json',
        returnConfiguredData: staticJson + 'returnForCohertTest.json',
        fetch_automatic_cohert: staticJson + 'fetchAutomaticCohert.json',
        // fetchAutomaticCohert: staticJson + 'fetchAutomaticCohert.json',

    };
    public files: any = {
        images: ['.png', '.jpg', '.JPEG'],
        imagesToShow:['.png', '.jpg', '.JPEG', '.gif'],
        audios: ['.mp3', '.m4a']
    };
}

export const DATE_FORMATS = {
    dashes: 1
};
