
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { AllsetupService } from '../../../services/allsetup.service';
import * as toastr from 'toastr';
import { asyncScheduler } from 'rxjs';

@Component({
  selector: 'app-cohert',
  templateUrl: './cohert.component.html',
  styleUrls: ['./cohert.component.css']
})
export class CohertComponent implements OnInit, OnChanges {
  @Input('inputData') inputData: any;
  @Input('type') type: any;
  @Input('parentLogic') parentLogic: any;
  @Input() SCHEDULETASKDETAILS: any;
  @Input() action: any;
  @Input('previousLists') previousLists: any; // this is for inside cohort
  @Input('previousLists1') previousLists1: any; // this is for outside cohort
  @Output() emitConditionalData: EventEmitter<any>;
  @Output() emitDeleteConditionalData: EventEmitter<any>;
  @Input('index') index: any;
  @Input('surveyDetails') surveyDetails: any;
  @Input() taskDetails: any;
  @Input('isOfflineProjectSupportEnabled') isOfflineProjectSupportEnabled: any;
  public showConditionBlock = true;
  public levelsContainer: any = [];
  public cancelButton = false;
  public hasResponse = false;
  public ConditionTask = false;
  public SelectInput = false;
  public isEdit = false;
  public multipleConditionLogic = '';
  public tableId = '';
  public compareQuestionList = [];
  public compareOptionList = [];
  public conditionObj = {
    conditionName: '',
    cohortType: '',
    task: '',
    conditionInTask: '',
    selectedImage: '',
    selectInput: [],
    hasResponse: false
  };
  public questionIDs = [];
  public cohortData: any = {};
  public questionsMapping = {};
  public isMR = false;
  public postObject: any = {};
  public cohortingTypes: any = [];
  public selectTaskTypes: any = [];
  public conditionInTaskTypes: any = [];
  public selectEmailImages: any = [];
  public selectQuestionOptions: any = [];
  show = true;
  showContent = false;
  public operatorBasedQuestions = ['openEndedNumeric', 'rating', 'ranking'];
  public CalenderBasedQuestions = ['dateCalender'];
  public compareQuestionsMapping = {}

  public selectedComparsionQuestion: any;
  item: any;
  isValid = true;
  CATEGORY_OPTIONS: any;
  muitiCohert = [{
    tableId: '',
    conditionsList: [],
    multipleConditionLogic: {
      'inLevel': '',
      'outLevel': '',
      'inTask': ''
    },
    conditionObj: {

    }

  }];
  CONDITION_OPTIONS: any = [
    {
      'value': 'Select an option',
      'label': 'Select an option'
    },
    {
      'value': 'Selected a Treatments(s)',
      'label': 'Selected a Treatments(s)'
    },
    {
      'label': 'Selected a button',
      'value': 'Selected a button'
    },
    {
      'label': 'Selected a Screen',
      'value': 'Selected a Screen'
    },
  ];

  condition = [];
  task: any;
  SELECT_INPUT: any = [];

  emailTypeOptions1 = [
    { label: 'Response Based', value: 'Response Based' },
    { label: 'Specific Input', value: 'Specific Input' },

  ];

  multiplelogic = [
    { label: 'AND', value: 'AND' },
    { label: 'OR', value: 'OR' },
  ];

  patient_data;
  public Checked = false;
  public CheckedBox = false;
  selectedCohorts = [];
  configuredData;
  public typeLabel: any = '';
  savedConditionInTask;
  savedInputType;
  public cohortOptions = [];
  public questionDetails;
  cohortDropdown: any;
  DateSettings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy'
  };

  //email and MR update changes
  public optionToShow:any = "";
  public qstnTypeOptions = [
    {
      value:"questionType",
      label:"Question Type"
    },
    {
      value:"optionType",
      label:"Option Type"
    }
  ];
  public selectedQuestion: any;
  public selectedQuestionType: any;
  public selectImages: any;
  public selectOptions: any;
  shareAllocationOptionShow = false;
  constructor(public _service: AllsetupService) {
    this.emitConditionalData = new EventEmitter<any>();
    this.emitDeleteConditionalData = new EventEmitter<any>();
  }

  public compareSelectTaskTypes = []

 public operator_list =[
    { value: '=', label: '=' },
    { value: '!=', label: '!=' },
   ];
 public compareTaskList:any;
  ngOnInit() {
    this.refreshCohortData();
    this.onLoadFunctions();
  }
  ngOnChanges() {
    this.onLoadFunctions();
  }
  onLoadFunctions() {
    if (this.type === 'inLevel') {
      this.typeLabel = 'Add logic based on previous tasks';
      this.cohortingTypes = this.previousLists['CohortingType'];
    }
    else if (this.type === 'inTask') {
      if(!this.inputData || this.inputData.length === 0){
        this.inputData = {}
        this.parentLogic = "";
      }
      this.typeLabel = 'Multiple Cohort';
      this.cohortingTypes = [{
        "label": "Specific Input",
        "value": "Specific Input"
      }];
      this.inputData['inTaskCohortSelected'] = 1;
      this.inputData.conditions = this.inputData.conditions ? this.inputData.conditions : [];
    } else {
      this.typeLabel = 'Outside Level Cohorting';
      this.cohortingTypes = this.previousLists1['CohortingType'];
    }
    if (this.type !== 'inTask') {
      this.inputData['inLevelCohortSelected'] = this.inputData['inLevelCohortSelected'] == 1 ? 1 : 0;
      this.inputData['outLevelCohortSelected'] = this.inputData['outLevelCohortSelected'] == 1 ? 1 : 0;
    }
    this.postObject = this.inputData;
    console.log(this.postObject, "x")
    if (this.postObject.conditions.length > 0) {
      this.postObject.conditions.forEach((element, i) => {
        this.muitiCohert[i] = element;
        this.muitiCohert[i].conditionObj = {};
      });
    }

    this.saveSectionInfo();
  }

  saveSectionInfo() {
    this.postObject.conditions.forEach(elements => {
      elements['conditionsList'].forEach(elementList => {
        elementList['edit'] = false;
      });
    });
    this.emitConditionalData.emit({ data: this.postObject.conditions, type: this.action, obj: this.postObject });
  }

  onKeydown(e, current) {
    if (e.which == 32) {
      return false;
    }

  }

  clone(data) {
    return JSON.parse(JSON.stringify(data));
  }

  cohortingTypeChange(value) {
    if (this.type === 'inLevel') {
      this.selectTaskTypes = this.previousLists['cohortingTypeBased'][value];
      this.compareTaskList = this.previousLists['compareTaskList'];
      if (this.compareTaskList.length > 0) {
        this.compareSelectTaskTypes = this.compareTaskList.filter(el => el.taskType == "MRTask")
      }
    } else if (this.type === 'inTask') {
      let taskID = "L"+this.SCHEDULETASKDETAILS.level+"_"+this.SCHEDULETASKDETAILS.surveyID;
      this.selectTaskTypes = [{ "label": taskID, "value": taskID, "taskType": "MRTask" }];
      this.compareTaskList = this.previousLists['compareTaskList'];
      if (this.compareTaskList.length > 0) {
        this.compareSelectTaskTypes = this.compareTaskList.filter(el => el.taskType == "MRTask")
      }
      this.compareSelectTaskTypes.push(this.selectTaskTypes[0]);
    }else {
      this.selectTaskTypes = this.previousLists1['cohortingTypeBased'][value];
      this.compareTaskList = this.previousLists1['compareTaskList'];
      if (this.compareTaskList.length > 0) {
        this.compareSelectTaskTypes = this.compareTaskList.filter(el => el.taskType == "MRTask")
      }
    }
  }




  addObjectTreatment(index, item) {
    if (item.tableId == ' ' || !item.multipleConditionLogic[this.type] || !item.conditionObj['cohortType'] || !item.conditionObj['conditionName'] || !item.conditionObj['task']) {
      toastr.info('Please fill all the fields', 'Warning');
      return;
    }
    this.postObject['conditionsList'] = this.postObject['conditionsList'] ? this.postObject['conditionsList'] : [];
    item.conditionObj['action'] = 'add';
    item.conditionObj['addNew'] = true;
    item.conditionObj['id'] = '';
    item.conditionObj['type'] = this.action;
    item.conditionObj['cohortLabels'] = [];
//     item.conditionObj['cohortCompareLabels'] = [];
    // item.conditionObj['hasResponse'] = item.conditionObj['hasResponse'];
    item.conditionObj['hasResponse'] = item.conditionObj['hasResponse'] ? item.conditionObj['hasResponse'] : false;

    try {
      if (this.isMR) {
        item.conditionObj['taskType'] = 'MRTask';
        if (this.questionDetails) {
          item.conditionObj['hasResponse'] = item.conditionObj['hasResponse'] ? item.conditionObj['hasResponse'] : false;
          item.conditionObj['cohortValue'] = this.getCohortRow(this.questionDetails.type);
          this.questionDetails.dataArray.forEach(element => {
              // tslint:disable-next-line: max-line-length
              if (item.conditionObj['cohortValue'].options.indexOf(element.id) > -1 && !item.conditionObj['cohortLabels'].includes(element.value)) {
                item.conditionObj['cohortLabels'].push(element.value);
              }
          });
        }
        if(item.conditionObj['selectedImage']){
          item.conditionObj.selectInput.push(item.conditionObj['selectedImage']);
          item.conditionObj['cohortLabels'] = item.conditionObj.selectInput;
        }
      } else if (item.conditionObj.task.includes('_P')) {
        // item.conditionObj['hasResponse'] = 'false';

        item.conditionObj['cohortLabels'] = [];
        if(item.conditionObj['operator']){
          item.conditionObj['cohortValue'] = {
            operator: item.conditionObj['operator']
          };
          delete item.conditionObj['operator'];
        }
        item.conditionObj['hasResponse'] = item.conditionObj['hasResponse'] ? item.conditionObj['hasResponse'] : false;
        item.conditionObj.selectInput.forEach(selectedOption => {
          this.SELECT_INPUT.forEach(option => {
            if (selectedOption === option.value) {
              item.conditionObj['cohortLabels'].push(option.label);
              return false;
            }
          });
        });
        //Exception case for clinic
        if(item.conditionObj['cohortType'] === "Image Based" && item.conditionObj['selectedImage']){
          item.conditionObj.selectInput.push(item.conditionObj['selectedImage']);
          item.conditionObj['cohortLabels'] = item.conditionObj.selectInput;
        }
        if(item.conditionObj['operator']){
          item.conditionObj['cohortValue'] = {
            operator: item.conditionObj['operator']
          };
          delete item.conditionObj['operator'];
        }
      } else {
        if(item.conditionObj['selectedImage']){
          item.conditionObj.selectInput.push(item.conditionObj['selectedImage']);
        }
        if(item.conditionObj['operator']){
          item.conditionObj['cohortValue'] = {
            operator: item.conditionObj['operator']
          };
          delete item.conditionObj['operator'];
        }
        item.conditionObj['cohortLabels'] = item.conditionObj.selectInput;
      }
    } catch (error) {
      console.log(error);
    }
    this.postObject['conditions'][index] = this.postObject['conditions'][index] ? this.postObject['conditions'][index] : {};
    this.postObject['conditions'][index]['conditionsList'] = this.postObject['conditions'][index]['conditionsList'] ?
      this.postObject['conditions'][index]['conditionsList'] : [];
    this.postObject['conditions'][index]['conditionsList'].push(JSON.parse(JSON.stringify(item.conditionObj)));
    this.postObject['conditions'][index]['tableId'] = item.tableId;
    this.postObject['conditionsList'].push(JSON.parse(JSON.stringify(item.conditionObj)));

    this.postObject['conditions'][index]['multipleConditionLogic'] = item.multipleConditionLogic;

    this.reinitialiseConditionObj(item);

    this.refreshCohortData();
    if(this.action === 'inTask'){
      delete  this.postObject.conditionsList;
       this.emitConditionalData.emit({ data: {}, type: this.action, list: this.postObject, parentCondition: this.parentLogic})
    }
    else {
     this.emitConditionalData.emit({ data: {}, type: this.action, list: this.postObject})
    }
    this.isEdit = false;
    this.optionToShow = "";
  }


  updateOption(i, item) {
    if (item.tableId == '' || !item.multipleConditionLogic[this.type] || !item.conditionObj['cohortType'] || !item.conditionObj['conditionName'] || !item.conditionObj['task']) {
      toastr.info('Please fill all the fields', 'Warning');
      return;
    }
    if (item.conditionObj['hasResponse']) {
      item.conditionObj['task'] = item.conditionObj['task'];
    }
    item.conditionObj['action'] = 'edit';
    item.conditionObj['type'] = this.action;
    if (this.isMR) {
      if (this.questionDetails) {
        item.conditionObj['cohortValue'] = this.getCohortRow(this.questionDetails.type);
        item.conditionObj['taskType'] = 'MRTask';

        this.questionDetails.dataArray.forEach(element => {
          if (this.questionDetails) {
            if (item.conditionObj['cohortValue'].options.indexOf(element.id) > -1) {
              item.conditionObj['cohortLabels'].push(element.value);
            }
          }
        });

      }
    } else if (item.conditionObj.task.includes('_P')) {
      item.conditionObj['cohortLabels'] = [];
      item.conditionObj.selectInput.forEach(selectedOption => {
        this.SELECT_INPUT.forEach(option => {
          if (selectedOption === option.value) {
            item.conditionObj['cohortLabels'].push(option.label);
            return false;
          }
        });
      });
    } else {
      item.conditionObj['cohortLabels'] = item.conditionObj.selectInput;
    }

    // this.postObject['conditionsList'][this.conditionObj['index']] = JSON.parse(JSON.stringify(this.conditionObj));

    this.postObject['conditions'][i]['conditionsList'][item.conditionObj['index']] = JSON.parse(JSON.stringify(item.conditionObj));
    this.reinitialiseConditionObj(item);
    this.emitConditionalData.emit({ data: {}, type: this.action, list: this.postObject});
    this.isEdit = false;
  }
  editList(condition, index, mainIndex, item) {
    this.isEdit = true;
    this.showConditionBlock = false;
    const task = condition.task;
    item.conditionObj = JSON.parse(JSON.stringify(condition));
    item.conditionObj['index'] = index;
    asyncScheduler.schedule(()=>{
      item.conditionObj.task = task;
      this.showConditionBlock = true;
    }, 300);
    this.savedConditionInTask = item.conditionObj.conditionInTask;
    this.savedInputType = JSON.parse(JSON.stringify(item.conditionObj.selectInput));
    this.emitConditionalData.emit({ data: condition, type: this.action, list: this.postObject });
  }
  hideIcon(index) {
    // tslint:disable-next-line: max-line-length
    if ((this.postObject.conditions[index].conditionsList.conditionName && this.postObject.conditions[index].conditionsList.conditionName.trim().length > 0)
      // tslint:disable-next-line: max-line-length
      || (this.postObject.conditions[index].conditionsList.task && this.postObject.conditions[index].conditionsList.task.trim().length > 0)) {
      return false;
    } else {
      return true;
    }
  }
  handleSingleInputDropdown(event, index, item) {
    if(this.type === 'inTask'){
      this.getQuestionIDs(this.taskDetails.sub_tasks, index, item);
      return;
    }
    if (event && item.conditionObj['task']) {
      let task_name = item.conditionObj['task'];
      if (this.type !== 'outLevel') {
        task_name = item.conditionObj['task'].substring(item.conditionObj['task'].indexOf('_') + 1, item.conditionObj['task'].length);
      }
      const inputJson = {
        projectCode: this.SCHEDULETASKDETAILS.projectCode,
        level: this.SCHEDULETASKDETAILS.level,
        task_name: task_name,
        conditionInTask: event,
        type: this.type,
        taskType: this.isMR ? 'MRTask' : 'Poll'

      };
      this.refreshCohortData();
      this._service.handle_single_input_dropdown(inputJson)
        .subscribe(
          response => {
            if (response['status'] === 'success') {
              if (!this.isMR) {
                this.SELECT_INPUT = response['result'];
                if (this.savedInputType) {
                  asyncScheduler.schedule(() => {
                    item.conditionObj[index].selectInput = this.savedInputType;
                    this.savedInputType = undefined;
                  }, 300);
                }
              } else {
                this.getQuestionIDs(response['result'], index, item);
              }
            }
          },
          err => {
            console.log(err);
            toastr.error('Failed to fetch data', 'ERROR');
          }
        );
    }
  }

   getCompareQuestionsByTask(event, index, item, cohortData) {
    this.cohortData.compare_questionID = null;
    let taskID = "L"+this.SCHEDULETASKDETAILS.level+"_"+this.SCHEDULETASKDETAILS.surveyID;
    if(taskID === this.cohortData['compare_task']){
      console.log(this.taskDetails.sub_tasks, "jndfj")
      this.getCompareQuestionIDs(this.taskDetails.sub_tasks, index, item);
    }
    else{
      if (event && this.cohortData['compare_task']) {
        let task_name = this.cohortData['compare_task'];
        if (this.type !== 'outLevel') {
          task_name = this.cohortData['compare_task'].substring(this.cohortData['compare_task'].indexOf('_') + 1, this.cohortData['compare_task'].length);
        }

        const inputJson = {
          projectCode: this.SCHEDULETASKDETAILS.projectCode,
          level: this.SCHEDULETASKDETAILS.level,
          task_name: task_name,
          conditionInTask: event,
          type: this.type,
          taskType: this.isMR ? 'MRTask' : 'Poll',
          compare_task_name: cohortData.compare_task
        };
        this._service.handle_single_input_dropdown(inputJson)
          .subscribe(
            response => {
              if (response['status'] === 'success') {
                  this.getCompareQuestionIDs(response['result'], index, item);
              }
            },
            err => {
              console.log(err);
              toastr.error('Failed to fetch data', 'ERROR');
            }
          );
      }
    }
  }

  inputChange(value, options, index, item) {
    if (!value) {
      return false;
    }
    if (item.conditionObj.cohortType === 'Image Based'){
      if(item.conditionObj.selectedImage){
        //clearing the existing value
        item.conditionObj.selectedImage = "";
      }
      let selectedTaskType;
      const foundItem = this.selectTaskTypes.find(item => item.value === value);
      if(foundItem.taskType){
        selectedTaskType = foundItem.taskType;
      }
      this.getImageList(value, index, item, selectedTaskType);
    }
    this.refreshCohortData();
    this.isMR = this.checkTypeOfInput(value, options);
    asyncScheduler.schedule(() => {
      if (this.type === 'inLevel') {
        this.conditionInTaskTypes = this.previousLists['conditionInTask'][value];
      }else if(this.type === 'inTask'){
        this.conditionInTaskTypes = [{
          "label": "Selected an Option",
          "value": "Selected an Option"
        }];
      } else {
        this.conditionInTaskTypes = this.previousLists1['conditionInTask'][value];
      }
      item.conditionObj['selectInput'] = [];
      if (this.savedConditionInTask) {
        asyncScheduler.schedule(() => {
          item.conditionObj[index].conditionInTask = this.savedConditionInTask;
          this.savedConditionInTask = undefined;
        }, 300);
      }
    }, 100);
  }
  getImageList(value, index, item, selectedTaskType){
    try {
      let task_name = value;
      const inputJson = {
        projectCode: this.SCHEDULETASKDETAILS.projectCode,
        level: this.SCHEDULETASKDETAILS.level,
        task_name: task_name,
        type: this.type,
        taskType: this.isMR ? 'MRTask' : 'Poll'

      };
      if(selectedTaskType){
        inputJson.taskType = selectedTaskType;
      }
      this._service.handle_image_input_dropdown(inputJson)
        .subscribe(
          response => {
            if (response['status'] === 'success') {
              if(response['showImageSelection']){
                this.optionToShow = 'showSelectImages';
                this.selectEmailImages = response['result'];
              } else if (response['showSelectQuestion']){
                this.optionToShow = 'showSelectQuestion';
                this.selectQuestionOptions = response['result'];
                this.getQuestionIDs(response['result'], index, item);
              }
            }
            else {
                this.selectEmailImages = [];
                this.selectQuestionOptions = [];
            }
          },
          err => {
            console.log(err);
            toastr.error('Failed to fetch data', 'ERROR');
          }
        );
    } catch (error) {
      console.log(error);
    }
  }
  checkTypeOfInput(value, options) {
    let flag = false;
    options.forEach(element => {
      if (element.value === value && element.taskType === 'MRTask') {
        flag = true;
        return true;
      }
    });
    return flag;
  }
  getQuestionIDs(data, index, item) {
    this.questionIDs = [];
    this.compareQuestionList = []
    this.questionsMapping = {};
    const temp = [];
    data.forEach(element => {
      temp.push({ label: element.tabname, value: element.id });
      this.questionsMapping[element.id] = element;
    });

    const newVar = data.filter(el => {
      if (this.operatorBasedQuestions.indexOf(el.type) > -1 || el.type == 'sum') {
        return el
      }
    })
    this.compareQuestionList = newVar.map(element => {
      return {
        label: element.tabname,
        value: element.id
      }
    })
    this.questionIDs = temp;
    asyncScheduler.schedule(() => {
      if (item.conditionObj['cohortValue'] && this.isMR) {
        this.cohortData = Object.assign(this.cohortData, item.conditionObj['cohortValue']);
      }
    }, 300);
  }

    getCompareQuestionIDs(data, index, item) {
    this.compareQuestionList = []
    this.compareQuestionsMapping = {};
    data.forEach(element => {
      this.compareQuestionsMapping[element.id] = element;
    });
    const newVar = data.filter(el => {
      if (this.operatorBasedQuestions.indexOf(el.type) > -1 || el.type == 'sum') {
        return el
      }
    })
    this.compareQuestionList = newVar.map(element => {
      return {
        label: element.tabname,
        value: element.id
      }
    })
  }
  // cancel() {
  //   this.reinitialiseConditionObj(index);
  //   this.cancelButton = false;
  // }

  setCompareOption(value) {
    this.compareOptionList = [];
    const temp = [];
    this.selectedComparsionQuestion = this.compareQuestionsMapping[value]
    if (this.questionDetails.type == 'sum') {
      this.compareQuestionsMapping[value].dataArray.forEach(element => {
        temp.push({ value: element.id, label: element.value, isSingle_select: element.isSingle_select, disabled: false });
      });
    } else {
      this.compareQuestionsMapping[value].dataArray.forEach(element => {
        temp.push({ value: element.id, label: element.value });
      });
    }
    this.compareOptionList = temp;
  }


  switchPrimary() {
    // this.postObject['cohortSelected'] = !this.postObject['cohortSelected'];
    if (this.action === 'inLevel') {
      if (this.postObject['inLevelCohortSelected'] == 0) {
        this.postObject['inLevelCohortSelected'] = 1;
      } else {
        this.postObject['inLevelCohortSelected'] = 0;
      }
    }
    if (this.action === 'outLevel') {
      if (this.postObject['outLevelCohortSelected'] == 0) {
        this.postObject['outLevelCohortSelected'] = 1;
      } else {
        this.postObject['outLevelCohortSelected'] = 0;
      }
    }
    this.emitConditionalData.emit({ data: {}, type: this.action, list: this.postObject});
  }

  onParentConditionChange(){
    this.emitConditionalData.emit({ data: {}, type: this.action, list: this.postObject, parentCondition: this.parentLogic });
  }

  // to sent cohortsvalues according to api requirement
  manipulateCohorts(type) {
    this.postObject.cohortvalue = [];
    if (this.selectedCohorts) {
      this.selectedCohorts.forEach(element => {
        this.postObject.cohortvalue.push(element.id);
      });
    }
  }
  remove(condition, index, pIndex, item) {
    if (!condition.id) {
      this.postObject.conditions[pIndex].conditionsList.splice(index, 1);
    } else {
      this.getDeleteConditionalData(condition, index, pIndex);
      // this.emitConditionalData.emit({ data: condition, type: 'delete', list: this.postObject });
    }
    this.reinitialiseConditionObj(item);
    item = null;
  }
  removeCards(item, index) {
    if (this.muitiCohert[index]['conditionsList'].length == 0) {
      this.muitiCohert.splice(index, 1);
    } else {
      this.deletecohortList(item, index);

    }


  }
  getDeleteConditionalData(data, index, pIndex?) {
    const inputJson = {
      level: this.SCHEDULETASKDETAILS.level,
      projectCode: this.SCHEDULETASKDETAILS.projectCode,
      taskId: this.SCHEDULETASKDETAILS.discussion_id ||
        this.SCHEDULETASKDETAILS.voice_id ||
        this.SCHEDULETASKDETAILS.emailID ||
        this.SCHEDULETASKDETAILS.patientID ||
        this.SCHEDULETASKDETAILS.taskCode ||
        this.SCHEDULETASKDETAILS.Rep_id ||
        this.SCHEDULETASKDETAILS.surveyID ||
        this.SCHEDULETASKDETAILS.tedx_id,
      id: data.id,
      action: 'delete'
    };
    if (this.surveyDetails) {
      inputJson['subTaskID'] = this.surveyDetails;
    }
    // tslint:disable-next-line: max-line-length
    const apiEndPoint = this.surveyDetails ? this._service.delete_question_level_cohort_data(inputJson) : this._service.delete_cohort_data(inputJson);
    // this._service.delete_cohort_data(inputJson)
    apiEndPoint.subscribe(
      response => {
        if (response['status'] === 'success') {
          this.postObject.conditions[pIndex].conditionsList.splice(index, 1);
          toastr.success('Deleted! successfully ', 'Success');
        } else {
          toastr.error('Failed to fetch data' + response['message'], 'ERROR');
        }
      },
      err => {
        console.log(err);
        toastr.error('Failed to fetch data', 'ERROR');
      }
    );
  }
  deletecohortList(data, index) {
    const inputJson = {
      level: this.SCHEDULETASKDETAILS.level,
      projectCode: this.SCHEDULETASKDETAILS.projectCode,
      taskId: this.SCHEDULETASKDETAILS.discussion_id ||
        this.SCHEDULETASKDETAILS.voice_id ||
        this.SCHEDULETASKDETAILS.emailID ||
        this.SCHEDULETASKDETAILS.patientID ||
        this.SCHEDULETASKDETAILS.taskCode ||
        this.SCHEDULETASKDETAILS.Rep_id ||
        this.SCHEDULETASKDETAILS.surveyID ||
        this.SCHEDULETASKDETAILS.tedx_id,
      tableId: data.tableId,
      action: 'delete'
    };
    if (this.surveyDetails) {
      inputJson['subTaskID'] = this.surveyDetails;
    }
    // tslint:disable-next-line: max-line-length
    const apiEndPoint = this.surveyDetails ? this._service.delete_question_level_cohort_table(inputJson) : this._service.delete_automatic_chort_table(inputJson);
    // this._service.delete_automatic_chort_table(inputJson)
    apiEndPoint.subscribe(
      response => {
        if (response['status'] === 'success') {
          this.postObject.conditions.splice(index, 1);
          this.muitiCohert.splice(index, 1);


          toastr.success('Deleted! successfully ', 'Success');
        } else {
          toastr.error('Failed to fetch data' + response['message'], 'ERROR');
        }
      },
      err => {
        console.log(err);
        toastr.error('Failed to fetch data', 'ERROR');
      }
    );
  }

  changeHasResponse(ind, item) {
    item.conditionObj['hasResponse'] = !item.conditionObj['hasResponse'];
  }

  dropDownSelect() {
    if (this.postObject['cohortType'] === 'Response Based') {
      this.hasResponse = true;
      this.ConditionTask = false;
      this.SelectInput = false;
    }
    // tslint:disable-next-line:one-line
    else if (this.postObject['cohortType'] === 'Specific Input') {
      this.hasResponse = false;
      this.ConditionTask = true;
      this.SelectInput = true;
    }
    this.CATEGORY_OPTIONS = [];
  }

  getCohortRow(type) {
    const obj: any = { questionID: this.cohortData.questionID, taskType: 'MRTask' };
    obj.options = this.clone(this.cohortData.options);
    if (this.operatorBasedQuestions.indexOf(type) > -1) {
      obj.value = this.cohortData.value;
      obj.operator = this.cohortData.operator;
     obj.compare_questionID = this.cohortData.compare_questionID;
      obj.compare_options = this.cohortData.compare_options;
      obj.compare_task = this.cohortData.compare_task;
          if(this.compareOptionList.length > 0){
          obj.cohortCompareLabels = [];
            this.compareOptionList.forEach(element => {
          if (obj.compare_options.indexOf(element.value) > -1) {
            obj.cohortCompareLabels.push(element.label);
          }
        });
          }
    } else if (this.CalenderBasedQuestions.indexOf(type) > -1) {
      obj.date = this.convertDateFormat(this.cohortData.date);
      obj.operator = this.cohortData.operator;
    }else if (type == 'singleSelect' || type == 'multiSelect') {
      obj.operator = this.cohortData.operator;
    }else if (type == 'dropdownSelection') {
      obj.operator = this.cohortData.operator;
      obj.dropdownOptions = this.cohortData.dropdownOptions;
    } else if (type == 'sum') {
      const temp = this.clone(this.cohortData.options);
//       obj.options = temp.map(el => el.value);
      obj.value = this.cohortData.value;
      obj.operator = this.cohortData.operator;
      obj.compare_questionID = this.cohortData.compare_questionID;
      obj.compare_options = this.cohortData.compare_options;
      obj.compare_task = this.cohortData.compare_task;
                if(this.compareOptionList.length > 0){
          obj.cohortCompareLabels = [];
            this.compareOptionList.forEach(element => {
          if (obj.compare_options.indexOf(element.value) > -1) {
            obj.cohortCompareLabels.push(element.label);
          }
        });
          }

    }
    return obj;
  }

  reinitialiseConditionObj(item) {
    item.conditionObj = {
      conditionName: '',
      cohortType: '',
      task: '',
      conditionInTask: '',
      selectInput: [],
      hasResponse: false
    };
  }
  changeQuestion(value) {
    if (!value) {
      return false;
    }
    if (value && this.questionsMapping[value]) {
      this.questionDetails = this.questionsMapping[value];
      this.cohortOptions = [];
      const temp = [];
      if (this.questionDetails.type == 'sum') {
        this.questionsMapping[value].dataArray.forEach(element => {
          temp.push({ value: element.id, label: element.value, isSingle_select: element.isSingle_select, disabled: false });
        });
      } else {
        this.questionsMapping[value].dataArray.forEach(element => {
          temp.push({ value: element.id, label: element.value });
        });
      }

      this.cohortOptions = temp;
    }
    if(value && this.type === 'multiCohert'){
      this.cohortOptions = [{"value": null,"label": ""}]
    }
  }
  changeDropdown(value) {
    if (!value) {
      return false;
    }
    // if (value && this.questionsMapping[value]) {
    //   this.questionDetails = this.questionsMapping[value];
    //   this.cohortOptions = [];
    //   const temp = [];
    //   this.questionsMapping[value].dataArray.forEach(element => {
    //     temp.push({ value: element.id, label: element.value });
    //   });
    //   this.cohortOptions = temp;
    // }
  }
  changeOption() {
    if(this.type === 'inTask'){
      this.questionsMapping[this.cohortData.questionID].dataArray.forEach((obj, ind) => {
        if (obj['id'] == this.cohortData.options) {
          this.cohortDropdown = obj['options'];
        }
      });
      this.cohortDropdown = this.cohortDropdown.map(el => ({ 'label': el.value, 'value': el.id }));
    }
    else{
      this.questionsMapping[this.cohortData.questionID].dataArray.forEach((obj, ind) => {
        if (obj['id'] == this.cohortData.options) {
          this.cohortDropdown = obj['dropdownOptions'];
        }
      });
      this.cohortDropdown = this.cohortDropdown.map(el => ({ 'label': el.value, 'value': el.id }));
    }
  }

  convertDateFormat(str) {
    const date = new Date(str);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const mnth = monthNames[date.getMonth()];
    const day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('/');
  }



  refreshCohortData() {
    this.cohortData = {
      options: [],
      dropdownOptions: [],
      questionID: '',
      compare_questionID: '',
      compare_options: [],
      compare_task:'',
      operator: '',
      value: null,
      date: new Date(),
      taskType: 'MRTask',
      operatorsList: [
        { value: '<', label: '<' },
        { value: '>', label: '>' },
        { value: '=', label: '=' },
        { value: '>=', label: '>=' },
        { value: '<=', label: '<=' },
      ]
    };
    this.questionDetails = undefined;
  }

  addcohortList() {
    this.muitiCohert.push(
      {
        tableId: '',
        conditionsList: [],
        multipleConditionLogic: {
          'inLevel': '',
          'outLevel': '',
          'inTask': ''
        },
        conditionObj: {}
      }
    );
  }

  changeOptionSumType(data) {
    const temp = JSON.parse(JSON.stringify(this.cohortOptions));
    if (data.length == 0) {
      temp.forEach(el => {
        el.disabled = false;
      });
      this.cohortOptions = temp;
    }
    // isSingle_select
    this.cohortData.options.forEach(el => {
      // tslint:disable-next-line: triple-equals
      if (el.isSingle_select == true) {
        temp.forEach(element => {
          if (element.isSingle_select === false) {
            element.disabled = true;
          }
        });
        this.shareAllocationOptionShow = false;
        this.cohortOptions = temp;
      } else {
        temp.forEach(element => {
          if (element.isSingle_select === true) {
            element.disabled = true;
          }
        });
        this.shareAllocationOptionShow = true;
        asyncScheduler.schedule(() => {
          this.cohortOptions = temp;
        }, 300);
      }
    });
  }

  questionImageChanges(value: any, type?, conditionObj?){
    if(type === 'questionID'){
      conditionObj['qstnType'] = "";
      conditionObj['imageOption'] = "";
      conditionObj['selectedImage'] = "";
      // conditionObj && 'selectedImage' in conditionObj && (conditionObj['selectedImage'] = ''); //use this type assignation incase of any bug
      this.selectedQuestion = value;
      this.cohortData.questionID = value;
    } else if(type === 'qstnType'){
      conditionObj['imageOption'] = "";
      conditionObj['selectedImage'] = "";
      this.selectedQuestionType = value;
      if(value === 'questionType'){
        this.selectImages = this.selectQuestionOptions[this.selectQuestionOptions.findIndex(item => item.value === this.selectedQuestion)].questionImage;
      } else if(value === 'optionType'){
        this.selectOptions = this.selectQuestionOptions[this.selectQuestionOptions.findIndex(item => item.value === this.selectedQuestion)].dataArray;
      }
    } else if(type === 'imageOption'){
      conditionObj['selectedImage'] = "";
      this.selectImages = this.selectOptions[this.selectOptions.findIndex(item => item.value === value)].optionImage
    }

  }

  validateCompareOption(data, type) {
    console.log(data)
    if (type == 'option') {
      data.value = null
    } else {
      data.compare_options = null
    }
  }




}
