// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  // API: 'http://surveyapp-dev-kl.knowledgelens.com/test_v3.0/',
  API: 'https://mzs.qa.zsservices.com/services/',
  REPORTAPI: 'https://mzs.qa.zsservices.com/reports/',
  // API: '/moment_zs/',
  // API: 'http://192.168.1.65:50089/',
  // API: 'http://localhost:50049/',
  // API: 'http://server.knowledgelens.com/test_v4.0/',
  // API: '/mzapi/',
  // API: 'http://momentzs-dev.knowledgelens.com/test_v4.0/',
  // API: 'http://951d55ee6188.ngrok.io/',
  // API: 'https://surveyapp.knowledgelens.com/momentZS_v2.0/',
  // API: 'http://192.168.1.147:50049/',
  // API: 'http://localhost:50049/',
  // API: 'http://server.knowledgelens.com/test_v4.0/',
  // API: '/mzapi/',
  // API: 'http://momentzs-dev.knowledgelens.com/test_v4.0/',
  firebase: {
    apiKey: "AIzaSyCIhBF22vOxrpFxReN1eo8xNGQcF-XlmqY",
    authDomain: "g0248-zs-momentzs-qa-app.firebaseapp.com",
    databaseURL: "https://g0248-zs-momentzs-qa-app-default-rtdb.firebaseio.com",
    projectId: "g0248-zs-momentzs-qa-app",
    storageBucket: "g0248-zs-momentzs-qa-app.appspot.com",
    messagingSenderId: "951380465475",
    appId: "1:951380465475:web:5d4155e8f4897fd065c1f6",
    measurementId: "G-SLVJ3V5JB1"
  },

  // API: 'http://b0994eacd43d.ngrok.io/',
  production: false
};
